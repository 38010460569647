<script setup lang="ts">
const { data: page } = await useAsyncData('index', () => queryContent('/').findOne())

useSeoMeta({
  title: page.value.title,
  ogTitle: page.value.title,
  description: page.value.description,
  ogDescription: page.value.description
})
</script>

<template>
  <ULandingSection headline="Instrumente digitale pentru calcule financiare." title="Calculatoare">
    <UPageGrid>
      <ULink class="text-left" to="/penalty">
        <ULandingCard class="h-full" title="Penalitate"
          description="Calculeaza penalitatile pentru facturile neachitate la timp." icon="i-heroicons-archive-box" />
      </ULink>
      <ULink class="text-left" to="/interest">
        <ULandingCard class="h-full" title="Dobanda"
          description="Determinați dobânda aplicabilă pentru facturile plătite cu întârziere."
          icon="i-heroicons-presentation-chart-line" />
      </ULink>
      <ULink class="text-left blur" to="/currency" disabled>
        <ULandingCard class="h-full" title="Curs valutar"
          description="Converteste sume intre valute la cursul BNM sau cel dorit."
          icon=" i-heroicons-currency-dollar" />
      </ULink>
    </UPageGrid>
  </ULandingSection>
</template>
